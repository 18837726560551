<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane :key="'first'" label="单据信息" name="first">
        <childTab1 ref="childTab1" @isShow="isShow" />
      </el-tab-pane>
      <el-tab-pane :key="'second'" :lazy="true" label="审批流转" name="second">
        <childTab3 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane :key="'third'" label="相关文档" name="third">
        <childTab2 />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DTdeliEditMain from '@/views/TransportationManagement/DTdeliManage/TabChild/DTdeliEditMain';
import DTdeliEditAbout from '@/views/TransportationManagement/DTdeliManage/TabChild/DTdeliEditAbout';
import DTdeliEditAppr from '@/views/TransportationManagement/DTdeliManage/TabChild/DTdeliEditAppr';

export default {
  name: 'DTdeliEdit',
  components: {
    childTab1: DTdeliEditMain,
    childTab2: DTdeliEditAbout,
    childTab3: DTdeliEditAppr
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
