import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keep4Decimal } from '@assets/js/regExUtil';

export const tableProperties = [
  store.state.index,
  { label: '进仓编号', prop: 'DTdeli_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '订单编号', prop: 'order_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '供货公司', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '收货公司', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '交货日期', prop: 'podr_pedate', itemType: 'input', input: false, sortable: false, widthAuto: true, formatter: val => getDateNoTime(val, false) },
  { label: '进仓日期', prop: 'dtdeli_date', itemType: 'input', input: false, sortable: false, widthAuto: true, formatter: val => getDateNoTime(val, false) },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    widthAuto: true,
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];

export const DTdeliMainFormProperties = [
  {
    label: '订单编号',
    prop: 'order_no',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: true
  },
  {
    label: '供货工厂',
    prop: 'supp_abbr',
    itemType: 'input',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    model: '',
    show: true
  },
  {
    label: '供货公司',
    prop: 'cptt_aid',
    itemType: 'select',
    size: 'small',
    rules: { required: false, message: ' ' },
    subItem: {
      disabled: false,
      required: false,
      type: 'select',
      options: {},
      clearable: true
    },
    subWidth: 80,
    disabled: false,
    model: '',
    show: true
  },
  {
    label: '收货公司',
    prop: 'cptt_name',
    itemType: 'input',
    size: 'small',
    model: '',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '收货人',
    prop: 'stff_aname',
    itemType: 'input',
    size: 'small',
    model: '',
    maxlength: 5,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '联系电话',
    prop: 'stff_pone',
    itemType: 'input',
    model: '',
    maxlength: 15,
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '收货地址',
    prop: 'stff_address',
    itemType: 'input',
    size: 'small',
    maxlength: 50,
    model: '',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '交货日期',
    prop: 'podr_pedate',
    itemType: 'date',
    model: '',
    formatTime: 'yyyy-MM-dd',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '进仓日期',
    prop: 'dtdeli_date',
    formatTime: 'yyyy-MM-dd',
    itemType: 'date',
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '交货要求',
    prop: 'delivery_requirements',
    itemType: 'input',
    model: '',
    size: 'small',
    type: 'textarea',
    maxlength: 255,
    rules: { required: false, message: ' ' },
    subWidth: 80,
    split: 24,
    disabled: false,
    show: true
  }
];

export const DTdeliSubTableProperties = [
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '中文名称', prop: 'prod_name', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '外包装数量', prop: 'prod_qpc', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '箱数', prop: 'prod_box_num', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '数量',
    prop: 'podr_part_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '订单单价',
    prop: 'old_prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120
  },
  {
    label: '开票单价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    subItem: { required: false, disabled: false, maxLength: 9, type: 'input', input: (val, row) => (row.prod_price = keep4Decimal(val)) }
  },

  { label: '部件单位', prop: 'prod_unit', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '生产日期',
    prop: 'part_pedate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false),
    subItem: { required: false, disabled: false, maxLength: 10, type: 'date', copyAndPaste: true }
  },
  { label: '长', prop: 'prod_boxl', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '宽', prop: 'prod_boxw', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '高', prop: 'prod_boxh', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '外箱毛重', prop: 'total_gross_weight', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '总毛重', prop: 'sum_gross_weight', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '外箱净重', prop: 'total_net_weight', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  { label: '总净重', prop: 'sum_net_weight', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '备注',
    prop: 'podr_part_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: { required: false, disabled: false, maxLength: '100', type: 'popoverInput', copyAndPaste: true }
  }
];
