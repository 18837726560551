<template>
  <div class="vg_wrapper">
    <EditHeader
      :btn="btn"
      :isShow="isShow"
      :special="true"
      :strForm="{ status: formStatus }"
      @closeEdit="closeEdit"
      @openEdit="openEdit"
      @refresh="initData"
      @submit="submit"
    >
      <div slot="functionButton" class="vg_ml_8">
        <el-button :disabled="!isShow" class="vg_mb_8" icon="el-icon-printer" size="small" type="info" @click="exportPDF(true)"> 打印</el-button>
        <el-button :disabled="!isShow" class="vg_mb_8" icon="el-icon-printer" size="small" type="info" @click="exportPDF(false)"> 下载PDF</el-button>
        <el-button v-if="formStatus === 2" :disabled="!isShow" class="vg_mb_8" size="small" type="info" @click="generateInvoicingNotification">
          生成开票通知单
        </el-button>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>进仓编号：</span>
          <el-input :value="otherForm.DTdeli_no" disabled size="small" style="width: 120px"></el-input>
        </div>
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      ref="dynamicForm"
      :form-list="mainForm.formProperties"
      :is-show="isShow"
      :split="mainForm.split"
      :total-width="mainForm.totalWidth"
      class="dynamicForm"
      v-bind="$attrs"
    />
    <DynamicUTable
      ref="dynamicUTable"
      :columns="DTdeliSubTableProperties"
      :is-show="isShow"
      :need-check-box="false"
      :need-pagination="false"
      :tableData="subTableData"
      @selection-change="val => (subSelections = val)"
    >
    </DynamicUTable>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser ref="userData" :isCookie="false" :stffForm="stffForm"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { DTdeliMainFormProperties, DTdeliSubTableProperties } from '@/views/TransportationManagement/DTdeliManage/dtdeli';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { DTdeliAPI } from '@api/modules/DTdeli';
import { changeStaffForm, downloadFile, getCptt } from '@api/public';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'DTdeliEditMain',
  components: {
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      activeName: 'first',
      isShow: true,
      btn: {},
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: DTdeliMainFormProperties
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      DTdeliSubTableProperties: cloneDeep(DTdeliSubTableProperties),
      DTdeliFeesTableData: [],
      formStatus: 0,
      otherForm: {},
      custList: [],
      deptList: [],
      stffList: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getInfo();
      await this.getDTdeliData();
      await this.changeMainFormProperties();
    },
    async getInfo() {
      this.mainForm.formProperties[2].options = await getCptt();
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    async changeMainFormProperties() {},
    getDTdeliData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      DTdeliAPI.getDTdeliById({ DTdeli_id: props.form_id }).then(({ data }) => {
        this.subTableData = data.form.dtDeliProdList;
        this.btn = data.btn;
        this.otherForm.DTdeli_no = data.form.DTdeli_no;
        this.otherForm.DTdeli_id = data.form.DTdeli_id;
        this.otherForm.cust_id = data.form.cust_id;
        this.otherForm.supp_id = data.form.supp_id;
        this.otherForm.cptt_id = data.form.cptt_id;
        this.formStatus = data.form.status;
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        changeStaffForm(this.stffForm, data.form);
      });
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable1 = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable1) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable1) tempStr += `子表-${dynamicUTable1}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      DTdeliAPI.editDeli(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.dtDeliProdList = this.subTableData.filter(x => (x.DTdeli_id ? true : !x.DTdeli_id && !x.destroy_flag));
      return Object.assign({}, formModel, this.otherForm);
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('DTdeliForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    generateInvoicingNotification() {
      if (!this.mainForm.formProperties.find(x => x.prop === 'dtdeli_date').model) return this.$message.warning('请选择进仓日期!');
      DTdeliAPI.addRequisitionByDtdeli(this.getSaveData()).then(({ data }) => {
        this.$message.success('生成成功!');
        this.jump('/requisition_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 179,
              form_id: data.form_id
            })
          )
        });
      });
    },
    exportPDF(open) {
      DTdeliAPI.exportDTDeliExcel({ DTdeli_id: this.otherForm.DTdeli_id }).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '.pdf', open: open });
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
